<template>
  <div class="addtrip">
    <div class="head_img">
      <div class="img_title">
        <div class="img">
          <img v-if="demandinfo.projectImg" :src="demandinfo.projectImg" alt="" />
          <img v-else src="../../../assets/image/Group 3163.png" alt="" />
        </div>
        <div class="title">
          <p class="merchant_name">{{ demandinfo.projectName }}</p>
          <div class="merchant_type">
            <span style="font-size: 14px; color: #078bff"><i class="el-icon-alarm-clock"></i></span>
            <span style="font-size: 14px; padding-left: 10px"
              >时间：{{ demandinfo.startTime }}至{{ demandinfo.endTime }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="essential_information">
      <div class="form_essential">
        <div class="form_essential_startime">
          <span style="color: #75757d; padding-left: 6px">行程时间：</span>
          <span style="color: #ff862e">{{ form.startTime }}</span>
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-width="110px">
          <el-form-item label="行程类型:" prop="tripType">
            <el-radio-group v-model="form.tripType">
              <el-radio
                v-for="item in codelist"
                :key="item.codeId"
                :label="item.codeId"
                @change="tripchange(item)"
                >{{ item.codeName }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <div v-if="form.tripType == 1">
            <div class="name_form">
              <el-form-item label="行程名称:" prop="tripName">
                <el-input v-model="form.tripName" placeholder="请输入行程名称" />
              </el-form-item>
              <el-form-item label="关联企业:" prop="">
                <el-select
                  v-model="form.companyId"
                  filterable
                  placeholder="请选择关联企业"
                  @change="companychange"
                >
                  <el-option
                    v-for="item in listData"
                    :key="item.id"
                    :label="item.companyFullName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="行程地点:" prop=" tripLocation">
                <el-input v-model="form.tripLocation" placeholder="请输入行程地点" />
              </el-form-item>
              <el-form-item label="企业简介:" prop="">
                <div class="txtree">
                  <el-input
                    v-model="form.companyProfile"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    placeholder="请输入内容"
                  />
                </div>
              </el-form-item>
              <!-- <el-form-item label="行程时间:" prop="">
                <div class="date_picker">
                  <el-date-picker v-model="form.startTime" type="datetime"  value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" placeholder="选择日期时间">
                </el-date-picker>
                </div>
              </el-form-item> -->
              <!-- <el-form-item label="企业logo:" prop="companyLogo">
                <div class="com_upload">
                  <img-big-upload
                    :imageShow.sync="form.companyLogo"
                    :addTitleShow="false"
                    @removeImg="removeImgDian"
                    @uploadOneImgShow="uploadOneImgShowDian"
                  />
                </div>
                <div class="img_tips">
                  <span style="color: #99bfff"
                    >(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span
                  >
                </div>
              </el-form-item> -->

              <el-form-item label="企业对接人:" prop="">
                <el-input v-model="form.companyContactPerson" placeholder="请输入企业对接人" />
              </el-form-item>

              <el-form-item label="参访流程:" prop="">
                <el-checkbox-group v-model="visitingProcedure">
                  <el-checkbox :label="'0'">演讲</el-checkbox>
                  <el-checkbox :label="'1'">参观</el-checkbox>
                  <el-checkbox :label="'2'">体验</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="记录员:" prop="">
                <el-input v-model="form.recorder" placeholder="请输入记录员名称" />
              </el-form-item>
            </div>
            <el-form-item label="参与嘉宾:" prop="">
              <el-input-number
                v-model="form.participants"
                :min="1"
                :max="10000"
                label="描述文字"
              ></el-input-number>
              <div style="margin-top: 20px" class="general_input">
                <div class="input_flex" v-for="(item, index) in list" :key="index">
                  <div style="display: flex">
                    <div style="display: flex">
                      <el-input v-model="item.general" placeholder="请输入姓名" />

                      <div style="padding: 0px 20px">
                        <span v-if="index == 0" style="color: #4e93fb" @click="addpartici"
                          ><i class="el-icon-circle-plus"></i
                        ></span>
                        <span v-if="index > 0" style="color: #ff7575" @click="delpartici(index)"
                          ><i class="el-icon-delete"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div v-if="form.tripType == 2">
            <div class="name_form">
              <el-form-item label="行程名称:" prop="tripName">
                <el-input v-model="form.tripName" placeholder="请输入行程名称" />
              </el-form-item>
              <el-form-item label="行程地点:" prop=" tripLocation">
                <el-input v-model="form.tripLocation" placeholder="请输入行程地点" />
              </el-form-item>
              <!-- <el-form-item label="行程时间:" prop="">
                <div class="date_picker">
                  <el-date-picker v-model="form.startTime" type="datetime"  value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" placeholder="选择日期时间">
                </el-date-picker>
                </div>
              </el-form-item> -->
              <el-form-item label="餐厅名称:" prop="">
                <el-input v-model="form.restaurantName" placeholder="请输入餐厅名称" />
              </el-form-item>
              <el-form-item label="用餐人数:" prop="">
                <div class="number_form">
                  <el-input-number
                    v-model="form.numberDine"
                    :min="1"
                    :max="10000"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </el-form-item>
              <!-- <el-form-item label="记录员:" prop="">
                <el-input v-model="form.recorder" placeholder="请输入记录员名称" />
              </el-form-item> -->
            </div>
          </div>
          <div v-if="form.tripType == 3">
            <div class="name_form">
              <el-form-item label="行程名称:" prop="tripName">
                <el-input v-model="form.tripName" placeholder="请输入行程名称" />
              </el-form-item>
              <el-form-item label="行程地点:" prop=" tripLocation">
                <el-input v-model="form.tripLocation" placeholder="请输入行程地点" />
              </el-form-item>
              <!-- <el-form-item label="行程时间:" prop="">
                <div class="date_picker">
                  <el-date-picker v-model="form.startTime" type="datetime"  value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" placeholder="选择日期时间">
                </el-date-picker>
                </div>

              </el-form-item> -->
              <el-form-item label="酒店logo:" prop="">
                <div class="com_upload">
                  <img-big-upload
                    :imageShow.sync="form.hotelLogo"
                    :addTitleShow="false"
                    @removeImg="removeImgDians"
                    @uploadOneImgShow="uploadOneImgShowDians"
                  />
                </div>
              </el-form-item>
              <el-form-item label="关联酒店:" prop="">
                <el-input v-model="form.hotelName" placeholder="请输入酒店名称" />
              </el-form-item>
              <!-- <el-form-item label="时间:" prop="commodityName">
                <el-date-picker
                  v-model="checktime"
                  unlink-panels
                  type="datetimerange"
                  range-separator="至"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="timeChanges"
                  start-placeholder="入住时间"
                  end-placeholder="离店时间"
                >
                </el-date-picker>
              </el-form-item> -->
              <el-form-item label="房型:" prop="">
                <el-input v-model="form.houseType" placeholder="请输入房型" />
              </el-form-item>
              <el-form-item label="房间数量:" prop="">
                <div class="number_form">
                  <el-input-number
                    v-model="form.numberRooms"
                    :min="1"
                    :max="10000"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </el-form-item>
              <!-- <el-form-item label="记录员:" prop="">
                <el-input v-model="form.recorder" placeholder="请输入记录员" />
              </el-form-item> -->
            </div>
          </div>
          <div v-if="form.tripType == 4">
            <div class="name_form">
              <el-form-item label="行程名称:" prop="tripName">
                <el-input v-model="form.tripName" placeholder="请输入行程名称" />
              </el-form-item>
              <el-form-item label="行程地点:" prop=" tripLocation">
                <el-input v-model="form.tripLocation" placeholder="请输入行程地点" />
              </el-form-item>
              <!-- <el-form-item label="行程时间:" prop="">
                <div class="date_picker">
                  <el-date-picker v-model="form.startTime" type="datetime"  value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" placeholder="选择日期时间">
                </el-date-picker>
                </div>
              </el-form-item> -->
              <el-form-item label="抵达信息" prop="launchTime" class="left">
                <!-- <div class="date_time">
                  <el-date-picker
                    clearable
                    v-model="form.arrivalTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择抵达时间"
                  >
                  </el-date-picker>
                </div> -->
                <div class="number_form" style="padding: 20px 0px">
                  <el-input-number
                    v-model="form.arrivalsNumber"
                    :min="1"
                    :max="10000"
                    label="描述文字"
                  ></el-input-number>
                  <span>人</span>
                </div>
                <el-input v-model="form.arrivalPlace" placeholder="请输入抵达地点" />
              </el-form-item>
              <el-form-item label="离开信息" prop="" class="left">
                <!-- <div class="date_time">
                  <el-date-picker
                    clearable
                    v-model="form.departureTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择离开时间"
                  >
                  </el-date-picker>
                </div> -->
                <div class="number_form" style="padding: 20px 0px">
                  <el-input-number
                    v-model="form.departureNumber"
                    :min="1"
                    :max="10000"
                    label="描述文字"
                  ></el-input-number>
                </div>
                <el-input v-model="form.departurePlace" placeholder="请输入离开地点" />
              </el-form-item>
              <el-form-item label="车辆信息:" prop="commodityName">
                <!-- <div class="number_form">
                  <el-input-number
                    v-model="form.num"
                    :min="1"
                    :max="10000"
                    label="描述文字"
                  ></el-input-number>
                </div> -->
                <div style="margin-top: 20px" class="general_inputs">
                  <div
                    class="input_flex"
                    v-for="(item, index) in form.vehicleInformationList"
                    :key="index"
                  >
                    <div style="display: flex">
                      <el-input v-model="item.driverName" placeholder="请输入司机名称" />
                      <div style="padding: 0px 10px"></div>
                      <el-input v-model="item.mobile" placeholder="请输入司机号码" />
                      <div style="padding: 0px 10px"></div>
                      <el-input v-model="item.plateNumber" placeholder="请输入车牌" />
                      <div style="padding: 0px 10px"></div>
                      <el-input v-model="item.carModel" placeholder="请输入车辆型号" />
                      <span v-if="index == 0" style="color: #4e93fb" @click="addgeneral"
                        ><i class="el-icon-circle-plus"></i
                      ></span>
                      <span v-if="index > 0" style="color: #ff7575" @click="delgeneral(index)"
                        ><i class="el-icon-delete"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addmanagement,
  getdemand,
  getCode,
  getVisitingCompany,
  editmanagement,
  getmanagement
} from '@/api/demand'

const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyName: null, //企业名称
  city: null, //所在地区
  companyId: null, //企业ID
  projectName: null, //项目名称
  labelName: null, //标签名称
  province: null //所在省份
})
export default {
  name: 'addtrip',
  computed: {
    id() {
      return this.$route.query.id * 1
    },
    inspectDemandId() {
      return this.$route.query.inspectDemandId * 1
    },
    startTime() {
      return this.$route.query.startTime
    }
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      form: {
        tripType: 1, //行程类型
        numberDine: 1, //用餐人数
        arrivalPlace: null,
        arrivalTime: null, //抵达时间
        arrivalsNumber: null,
        checkinTime: null, //入驻时间
        checkoutTime: null, //离店时间
        companyContactPerson: null, //企业对接人
        companyId: null, //企业id
        companyLogo: '', //企业logo
        companyProfile: null, //企业简介
        createId: null,
        createTime: null,
        departureNumber: null,
        departurePlace: null,
        departureTime: null, //离开时间
        endTime: null, //行程结束时间
        hotelLogo: '', //酒店logo
        hotelName: null, //关联酒店
        houseType: null, //房型
        id: null,
        inspectDemandId: null,
        isAvailable: 1,
        numberRooms: null, //房间数量
        participants: null, //参与嘉宾
        participantsName: null,
        recorder: null, //记录员
        restaurantName: null, //餐厅名称
        startTime: null, //行程开始时间
        tripLocation: null, //行程地点
        tripName: null, //行程名称
        updateId: null,
        updateTime: null,
        visitingProcedure: [], //参考流程
        vehicleInformationList: [
          {
            carModel: null, //车辆型号
            driverName: null, //司机名称
            id: null,
            mobile: null, //司机号码
            plateNumber: null, //车牌
            tripId: this.$route.query.id
          }
        ]
      },
      visitingProcedure: [], //参访流程
      rules: {
        tripType: [{ required: true, message: '请勾选行程类型', trigger: 'change' }],
        tripName: [{ required: true, message: '请输入行程名称', trigger: 'blur' }],
        companyProfile: [{ required: true, message: '请输入企业简介', trigger: 'blur' }]
      },
      list: [
        {
          general: ''
        }
      ],
      projectime: [], //行程时间
      listData: [],
      codelist: [],
      checktime: [],
      demandinfo: {}
    }
  },
  created() {
    this.search()
    this.getCode()
    this.getdemand()
    if (this.id) {
      this.getmanagement()
    }
  },
  methods: {
    //获取信息
    async getdemand() {
      const res = await getdemand(this.inspectDemandId)
      if (res.data.resultCode == 200) {
        this.demandinfo = res.data.data
        this.form.tripName = '参访企业'
      }
    },
    //获取行程管理详细信息
    async getmanagement() {
      const res = await getmanagement(this.id)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        if (this.form.participantsName != null) {
          this.list = []
          this.form.participantsName = this.form.participantsName.split(',')
          this.form.participantsName.forEach((o) => {
            this.list.push({
              general: o
            })
          })
        }

        this.form.tripType = this.form.tripType * 1
        // this.projectime.push(this.form.startTime)
        // this.projectime.push(this.form.endTime)

        if (this.form.checkinTime != null) {
          this.checktime.push(this.form.checkinTime)
          this.checktime.push(this.form.checkoutTime)
        }
        if (this.form.visitingProcedure === null) {
          this.visitingProcedure = []
        } else {
          this.visitingProcedure = this.form.visitingProcedure.split(',')
        }
      }
    },
    //选择行程类型
    tripchange(item) {
      this.form.tripName = item.codeName
    },
    //选择关联企业关联地址
    companychange(val) {
      this.listData.forEach((el) => {
        if (val === el.id) {
          this.form.tripLocation = el.enterpriseAddress
          this.form.companyProfile = el.enterpriseIntro
          this.form.companyLogo = el.companyLogo
        }
      })
    },
    async search() {
      this.form.startTime = this.startTime
      const res = await getVisitingCompany({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.listData = res.data.data
      }
    },
    //行程类型code
    async getCode() {
      const res = await getCode({ code: '007' })
      if (res.data.resultCode == 200) {
        this.codelist = res.data.data
      }
    },
    //添加车辆信息
    addgeneral() {
      this.form.vehicleInformationList.push({
        carModel: '',
        driverName: '',
        id: null,
        mobile: '',
        plateNumber: '',
        tripId: this.id
      })
    },
    //删除车辆信息
    delgeneral(index) {
      this.form.vehicleInformationList.splice(index, 1)
    },
    //新增嘉宾
    addpartici() {
      this.list.push({
        general: ''
      })
    },
    delpartici(index) {
      this.list.splice(index)
    },
    //时间
    // timeChange() {
    //   console.log(this.projectime)
    //   if (this.projectime.length > 1) {
    //     this.form.startTime = this.projectime[0]
    //     this.form.endTime = this.projectime[1]
    //   } else {
    //     this.form.startTime = ''
    //     this.form.endTime = ''
    //   }
    // },
    //入住时间
    //时间
    timeChanges() {
      if (this.checktime.length > 1) {
        this.form.checkinTime = this.checktime[0]
        this.form.checkoutTime = this.checktime[1]
      } else {
        this.form.checkinTime = ''
        this.form.checkoutTime = ''
      }
    },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.companyLogo = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.companyLogo = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.hotelLogo = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.hotelLogo = ''
    },
    /** 提交按钮 */
    submitForm() {
      this.form.inspectDemandId = this.inspectDemandId
      if (this.visitingProcedure != null) {
        this.form.visitingProcedure = this.visitingProcedure
        this.form.visitingProcedure = this.form.visitingProcedure.toString()
      }
      let namelist = []
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.list.forEach((o) => {
            namelist.push(o.general)
          })
          this.form.participantsName = namelist.toString()
          if (this.form.id == null) {
            addmanagement(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('保存成功')
                this.$router.go(-1)
              } else {
                this.$message.warning(response.data.message)
              }
            })
          } else {
            editmanagement(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('保存成功')
                this.$router.go(-1)
              } else {
                this.$message.warning(response.data.message)
              }
            })
          }
        }
      })
    },
    //取消
    cancel() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-form-item__content {
  line-height: 42px;
  position: relative;
  font-size: 14px;
}
::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 44px;
  line-height: 42px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .general_inputs .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 44px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 170px;
}
::v-deep .number_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 44px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 179px;
}
::v-deep .date_time .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 44px;
  line-height: 41px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 250px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 596px;
  vertical-align: bottom;
  font-size: 14px;
}
.addtrip {
  .general_inputs {
    width: 845px;
    border: 1px solid #d4d4d4;
    padding: 14px 16px;

    .input_flex {
      display: flex;
      justify-content: space-between;
      padding: 7px 0px;
      span {
        padding-left: 15px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .dialog-footer {
    border-top: 1px solid #ededed;
    padding: 20px 0px;
    background: #ffffff;
    display: flex;
    justify-content: center;
  }
  .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 15px 10px;
    background: #ffffff;
    .storeCenter_item_top_center {
      width: 600px;
    }
    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      img {
        width: 68px;
        height: 68px;
      }

      .title {
        margin: 0px 0px 0px 10px;
        display: flex;
        flex-direction: column;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -15px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
        }
      }
    }
  }
  .essential_information {
    margin-top: 20px;
    background: #ffffff;
    padding-top: 15px;
    .form_essential {
      padding-bottom: 90px;
      .form_essential_startime {
        width: 265px;
        height: 38px;
        margin-left: 30px;
        background: #fafafa;
        border-radius: 8px;
        line-height: 38px;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .general_input {
      width: 845px;
      border: 1px solid #d4d4d4;
      padding: 14px 16px;
      display: flex;
      flex-wrap: wrap;
      .input_flex {
        padding: 7px 0px;
        span {
          padding-left: 15px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
